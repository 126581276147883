import React, { useLayoutEffect, useEffect, useRef, useState } from "react"
import { connect } from "react-redux"

import { useWindowSize } from "../../utils/hooks"

import {
  toggleProjects,
  toggleCredits,
  setProject,
  setRotationMultiplier,
} from "../state/store"
import { Modal, Preview, Previews, Gate } from "../components/index"
import { hasHover } from "../../utils/device"

import lazysizes from "lazysizes"

const Project = ({
  showCredits,
  hasProtectedAccess,
  dispatch,
  pageContext,
}) => {
  const { frontmatter, html } = pageContext.current

  const { height, width } = useWindowSize()
  const DRAG = 0.6
  const STRENGTH = 0.075
  const scrollEl = useRef()
  const scrollMax = useRef(0)
  const rafId = useRef()
  const VS = useRef()

  const [standardScroll, setStandardScroll] = useState(false)
  useEffect(() => {
    setStandardScroll(hasHover())
  })

  useState(() => {
    dispatch(setRotationMultiplier(10))
    dispatch(toggleProjects(false))
    dispatch(setProject(pageContext))
    if (typeof document !== "undefined")
      document.body.classList.add("is-project")
  })

  const onClickOutside = () => {
    showCredits && dispatch(toggleCredits(false))
  }

  const onClickInside = (e) => {
    e.stopPropagation()
  }

  const updateScrollMax = () => {
    scrollMax.current =
      scrollEl.current.getBoundingClientRect().height * -1 + height
  }
  useLayoutEffect(() => {
    if (scrollEl.current === undefined) return
    scrollMax.current =
      scrollEl.current.getBoundingClientRect().height * -1 + height
    const intervalId = setInterval(updateScrollMax, 2000)
    // update on lazyloaded item
    scrollEl.current.addEventListener("lazybeforeunveil", updateScrollMax)
    return () => {
      clearInterval(intervalId)
      scrollEl.current.removeEventListener("lazybeforeunveil", updateScrollMax)
    }
  }, [height, scrollEl, hasProtectedAccess])

  useLayoutEffect(() => {
    if (rafId.current) cancelAnimationFrame(rafId.current)

    let target = 0
    let scrollValue = 0
    let scrollVelocity = 0
    let scrolling = false
    let velocity = 0

    const updateScroll = () => {
      rafId.current = requestAnimationFrame(updateScroll)

      const previousValue = scrollValue

      if (scrolling) {
        scrollValue = target
        velocity = scrollValue - previousValue
      } else if (velocity) {
        scrollValue += velocity
        target += velocity
        velocity *= 0.92
        velocity = Math.abs(velocity) < 0.01 ? 0 : velocity
      }

      scrolling = false

      scrollValue = Math.min(0, Math.max(scrollMax.current, scrollValue))

      scrollEl.current.style = `transform: translate3d(0, ${Math.min(
        0,
        scrollValue
      )}px, 0)`
    }

    const onScroll = (e) => {
      scrolling = true
      target += e.deltaY

      target = Math.min(0, Math.max(scrollMax.current, target))
    }

    async function withImport() {
      const { default: VirtualScroll } = await import("virtual-scroll")
      document.body.classList.toggle("has-virtualscroll", !standardScroll)
      if (!width || !scrollEl.current || standardScroll) return
      VS.current = new VirtualScroll({
        el: scrollEl.current,
        touchMultiplier: 1,
      })
      VS.current.on(onScroll)
      updateScroll()
    }
    withImport()

    return () => {
      if (VS.current) {
        VS.current.off(onScroll)
        VS.current.destroy()
      }
      if (rafId.current) cancelAnimationFrame(rafId.current)
    }
  }, [width, scrollEl, hasProtectedAccess])

  const renderSection = (section, i) => {
    switch (section.type) {
      case "head":
        return (
          <div className="s-hero" key={i}>
            <img
              className="s-hero__bg-img"
              sizes="100vw"
              srcSet={`/content${frontmatter.slug}${frontmatter.slug}_hero_sm.jpg 1024w, /content${frontmatter.slug}${frontmatter.slug}_hero_lg.jpg 2880w`}
              alt=""
            />
            <div className="s-hero__logo-wrapper">
              <div
                className="s-hero__logo"
                style={{
                  backgroundImage: `url(/content${frontmatter.slug}${frontmatter.slug}_logo.png)`,
                }}
              ></div>
            </div>
          </div>
        )
        break
      case "large":
        return (
          <div className="s-large" key={i}>
            {section.source ? (
              <Preview
                className="s-large__video"
                src={`${section.source}`}
                mobileVideo
                autoPlay
                lazy
                size="cover"
                hasSound={"sound" in section ? section.sound : false}
              />
            ) : (
              <img
                className="s-large__img"
                sizes="100vw"
                srcSet={`/content${frontmatter.slug}/${section.picture}_sm.jpg 1024w, /content${frontmatter.slug}/${section.picture}_lg.jpg 2880w`}
                alt=""
              />
            )}
          </div>
        )
        break
      case "text":
        return (
          <div className="s-text" key={i}>
            <div
              className="s-text__inner wysiwyg wysiwyg--underline"
              style={{
                backgroundColor: section.background,
                color: section.color,
                "--bg-color": section.background,
                "--color": section.color,
                "--hover-color": section.hover_color
                  ? section.hover_color
                  : "currentColor",
              }}
              dangerouslySetInnerHTML={{ __html: section.text }}
            ></div>
          </div>
        )
        break
      case "half":
        return (
          <div className={`s-half ${section.type}`} key={i}>
            <div className="s-half__inner">
              {section.content.map((content, i) =>
                content.source ? (
                  <div className="s-half__col" key={i}>
                    <Preview
                      className="s-half__video"
                      src={`${content.source}`}
                      mobileVideo
                      autoPlay
                      lazy
                      size="cover"
                      hasSound={"sound" in content ? content.sound : false}
                    />
                  </div>
                ) : (
                  <div className="s-half__col" key={i}>
                    <img
                      className="s-half__img"
                      sizes="(min-width: 768px) 50vw, 100vw"
                      srcSet={`/content${frontmatter.slug}/${content.picture}_sm.jpg 1024w, /content${frontmatter.slug}/${content.picture}_lg.jpg 2880w`}
                      alt=""
                    />
                  </div>
                )
              )}
            </div>
          </div>
        )
        break
      case "half-force":
        return (
          <div className={`s-half ${section.type}`} key={i}>
            <div className="s-half__inner">
              <div className="s-half__col">
                <img
                  className="s-half__img"
                  sizes="50vw"
                  srcSet={`/content${frontmatter.slug}/${section.picture}_1_sm.jpg 1024w, /content${frontmatter.slug}/${section.picture}_1_lg.jpg 2880w`}
                  alt=""
                />
              </div>
              <div className="s-half__col">
                <img
                  className="s-half__img"
                  sizes="50vw"
                  srcSet={`/content${frontmatter.slug}/${section.picture}_2_sm.jpg 1024w, /content${frontmatter.slug}/${section.picture}_2_lg.jpg 2880w`}
                  alt=""
                />
              </div>
            </div>
          </div>
        )
        break
      case "third":
        return (
          <div className={`s-third ${section.type}`} key={i}>
            <div className="s-third__inner">
              <div className="s-third__col">
                <img
                  className="s-third__img"
                  sizes="(min-width: 768px) 33.3vw, 100vw"
                  srcSet={`/content${frontmatter.slug}/${section.picture}_1_sm.jpg 1024w, /content${frontmatter.slug}/${section.picture}_1_lg.jpg 2880w`}
                  alt=""
                />
              </div>
              <div className="s-third__col">
                <img
                  className="s-third__img"
                  sizes="(min-width: 768px) 33.3vw, 100vw"
                  srcSet={`/content${frontmatter.slug}/${section.picture}_2_sm.jpg 1024w, /content${frontmatter.slug}/${section.picture}_2_lg.jpg 2880w`}
                  alt=""
                />
              </div>
              <div className="s-third__col">
                <img
                  className="s-third__img"
                  sizes="(min-width: 768px) 33.3vw, 100vw"
                  srcSet={`/content${frontmatter.slug}/${section.picture}_3_sm.jpg 1024w, /content${frontmatter.slug}/${section.picture}_3_lg.jpg 2880w`}
                  alt=""
                />
              </div>
            </div>
          </div>
        )
        break
      case "third-force":
        return (
          <div className={`s-third ${section.type}`} key={i}>
            <div className="s-third__inner">
              <img
                className="s-third__img"
                sizes="33.3vw"
                srcSet={`/content${frontmatter.slug}/${section.picture}_1_sm.jpg 1024w, /content${frontmatter.slug}/${section.picture}_1_lg.jpg 2880w`}
                alt=""
              />
              <img
                className="s-third__img"
                sizes="33.3vw"
                srcSet={`/content${frontmatter.slug}/${section.picture}_2_sm.jpg 1024w, /content${frontmatter.slug}/${section.picture}_2_lg.jpg 2880w`}
                alt=""
              />
              <img
                className="s-third__img"
                sizes="33.3vw"
                srcSet={`/content${frontmatter.slug}/${section.picture}_3_sm.jpg 1024w, /content${frontmatter.slug}/${section.picture}_3_lg.jpg 2880w`}
                alt=""
              />
            </div>
          </div>
        )
        break
      default:
        return <span>{section.type}</span>
        break
    }
  }

  if (frontmatter.protected && !hasProtectedAccess) {
    return <Gate></Gate>
  }

  return (
    <div
      className={`project ${standardScroll ? "" : "project--virtualscroll"} ${
        frontmatter.video_full ? "project--video" : ""
      } ${frontmatter.protected ? "project--protected" : ""}`}
      style={{ backgroundColor: frontmatter.color }}
      onClick={onClickOutside}
    >
      {/*
          <div className="debug">
            <button onClick={onClickCredits}>credits</button>
            <button onClick={onClickPrev}>prev</button>
            <button onClick={onClickNext}>next</button>
          </div>
        */}

      {frontmatter.sections ? (
        <div className="project__scroll" ref={scrollEl}>
          <Previews>
            {frontmatter.sections &&
              frontmatter.sections.map((section, i) =>
                renderSection(section, i)
              )}
          </Previews>
        </div>
      ) : (
        ""
      )}

      {frontmatter.video_full ? (
        <Previews>
          <Preview
            className="project__player"
            cover={`/content/${frontmatter.slug}/cover.jpg`}
            src={frontmatter.video_full}
            mobileVideo
            lazy
            autoPlay
            size="contain"
            hasSound={"sound" in frontmatter ? frontmatter.sound : false}
          />
        </Previews>
      ) : (
        ""
      )}

      <Modal
        className={`project__credits ${showCredits ? "is-active" : ""}`}
        onClick={onClickInside}
        onClickClose={onClickOutside}
      >
        <div
          className="modal-content wysiwyg wysiwyg--project"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      </Modal>
    </div>
  )
}

export default connect(
  (state) => ({
    showCredits: state.showCredits,
    hasProtectedAccess: state.hasProtectedAccess,
  }),
  null
)(Project)
